import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./App.css";
import Loader from "./loader";
import useSound from "use-sound";
import Audio from "./Audio.mpeg";
import ConnectButton from "./web3/components/connectButton";
import { ToastContainer, toast } from "react-toastify";
import Mint from "./components/Mint";

import successGif from "./success.gif";
import "react-toastify/dist/ReactToastify.css";

import web3Config from "./web3.config";

import { useRecoilValue } from "recoil";
import { mintStateAtom } from "./state/mintState";
import { txHashAtom } from "./state/txHash";

function App() {
  const variants = {
    animate: { opacity: 1, y: -5 },
    initial: { opacity: 0, y: 0 },
  };

  const mintState = useRecoilValue(mintStateAtom);
  const txHash = useRecoilValue(txHashAtom);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [play, { pause }] = useSound(Audio, {
    loop: true,
  });

  useEffect(() => {
    if (mintState === "minting" && !isPlaying) {
      playSong();
    }
  }, [mintState]);

  useEffect(() => {
    const loader = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loader);
  }, []);

  function playSong() {
    setIsPlaying(true);
    play();
  }

  function stopSong() {
    setIsPlaying(false);
    pause();
  }

  useEffect(() => {
    const loader = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loader);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loadingContainer">
          <Loader />
        </div>
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="container"
          >
            <ToastContainer
              position="bottom-center"
              hideProgressBar
              autoClose={2000}
              theme="dark"
            />
            <div
              className="bg"
              style={{
                filter: visible ? "blur(20px)" : "blur(0px)",
              }}
            ></div>
            <div className="header">
              <div className="leftcontainer pc">
                <a href="https://twitter.com/libraryonchain?s=21&t=Civ3adGjnVKodfaCOYN7Vw">
                  Twitter
                </a>{" "}
                /{" "}
                <a href="https://discord.com/invite/libraryonchain">Discord</a>{" "}
                / <a href="https://medium.com/@Libraryonchain">Medium</a> /{" "}
                <a href="https://opensea.io/collection/library-onchain">
                  OpenSea
                </a>
              </div>

              <div
                className="mob"
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16" cy="16" r="15.5" stroke="#D0EBC3" />
                  <path
                    d="M24.1856 16C24.1856 20.3515 23.2271 24.2662 21.7022 27.0752C20.1668 29.9035 18.1284 31.5 15.9999 31.5C13.8714 31.5 11.833 29.9035 10.2976 27.0752C8.77277 24.2662 7.81421 20.3515 7.81421 16C7.81421 11.6485 8.77277 7.73378 10.2976 4.92484C11.833 2.09645 13.8714 0.5 15.9999 0.5C18.1284 0.5 20.1668 2.09645 21.7022 4.92484C23.2271 7.73378 24.1856 11.6485 24.1856 16Z"
                    stroke="#D0EBC3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.384286 15.5959C0.13271 15.726 0 15.8612 0 16C0 17.0196 7.16344 17.8461 16 17.8461C24.8366 17.8461 32 17.0196 32 16C32 15.9087 31.9427 15.8191 31.832 15.7314L31.3845 15.9999H30.9886C30.9886 15.9999 30.9887 15.9999 30.9887 16C30.8732 16.0402 30.7376 16.0821 30.5807 16.1249C29.8156 16.3336 28.68 16.5283 27.2432 16.6941C24.3777 17.0247 20.4024 17.2307 16 17.2307C11.5976 17.2307 7.6223 17.0247 4.75683 16.6941C3.31999 16.5283 2.18436 16.3336 1.41932 16.1249C1.2624 16.0821 1.12684 16.0402 1.01128 16C1.01132 15.9999 1.01136 15.9999 1.0114 15.9999H0.922908L0.384286 15.5959Z"
                    fill="#D0EBC3"
                  />
                  <path d="M15.543 0V31.0857" stroke="#D0EBC3" />
                </svg>
              </div>

              <div className="mobOverlay"></div>

              <div className="rightcontainer">
                <ConnectButton
                  Button={({ text }) => <button>{text}</button>}
                  text={{
                    unConnected: "Connect to Wallet",
                    connected: "Connected Wallet",
                  }}
                />
              </div>
            </div>

            <div className="content">
              <AnimatePresence mode="popLayout">
                {!visible ? (
                  <motion.div
                    className="textContainer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="logoContainer"
                    >
                      <img src={require("./logo.png")} alt="" />
                    </motion.div>
                    <div
                      className={`text ${
                        mintState == "minted" ? "augMint" : ""
                      }`}
                    >
                      {mintState == "minted" ? (
                        <div className="minted">
                          <img className="success" src={successGif} alt="" />
                          <p>Minted!</p>
                          <p
                            className="ethscanlink"
                            onClick={() => {
                              window.open(
                                `${web3Config.etherScanRoot}${txHash}`,
                                "_blank"
                              );
                            }}
                          >
                            View Transaction
                          </p>
                        </div>
                      ) : (
                        <p>
                          The raven arrived from the Library this morning, Your
                          King. The Conclave has met, considered reports from
                          maesters all over the blockchains, and declared this
                          great summer done at last. The longest summer in
                          living memory.
                        </p>
                      )}
                    </div>
                  </motion.div>
                ) : (
                  <motion.div variants={variants} className="mobLinks">
                    <motion.a
                      initial={variants.initial}
                      animate={variants.animate}
                      href="https://twitter.com/libraryonchain?s=21&t=Civ3adGjnVKodfaCOYN7Vw"
                    >
                      Twitter
                    </motion.a>
                    <motion.a
                      initial={variants.initial}
                      animate={variants.animate}
                      transition={{ delay: 0.1 }}
                      href="https://discord.com/invite/libraryonchain"
                    >
                      Discord
                    </motion.a>
                    <motion.a
                      initial={variants.initial}
                      animate={variants.animate}
                      transition={{ delay: 0.15 }}
                      href="https://medium.com/@Libraryonchain"
                    >
                      Medium
                    </motion.a>
                    <motion.a
                      initial={variants.initial}
                      animate={variants.animate}
                      transition={{ delay: 0.2 }}
                      href="https://opensea.io/collection/library-onchain"
                    >
                      OpenSea
                    </motion.a>
                  </motion.div>
                )}
              </AnimatePresence>

              {mintState == "minted" ? <></> : <Mint />}

              <div className="border"></div>
              <div className="bottom">
                Library Onchain 📚⛓
                <span onClick={isPlaying ? stopSong : playSong}>
                  {isPlaying ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-pause-circle"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <line x1="10" y1="15" x2="10" y2="9" />
                      <line x1="14" y1="15" x2="14" y2="9" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-play-circle"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <polygon points="10 8 16 12 10 16 10 8" />
                    </svg>
                  )}
                </span>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
}

export default App;

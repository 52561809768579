import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { getMintPrice } from "../web3/getMintPrice";

import { getMintStage } from "../web3/getMintStage";
import { getContract } from "../web3/core";
import { connected } from "../web3/state/connect";

import { mintStateAtom } from "../state/mintState";
import { useAccount } from "@web3modal/react";

import { ethers } from "ethers";
import { txHashAtom } from "../state/txHash";

import { getProof } from "../waitlist";

const errorToast = (message) => {
  toast.error(message, { position: "bottom-center" });
};

function generateProof(address) {
  //mock generate proof
  return getProof(address);
}

export default function Mint() {
  const connect = useRecoilValue(connected);

  const { isConnected, address } = useAccount();

  const setTxHash = useSetRecoilState(txHashAtom);

  const [mintState, setMintState] = useRecoilState(mintStateAtom);

  useEffect(() => {
    console.log(mintState);
  }, [mintState]);

  const onClick = async () => {
    //Prevent button spam

    if (mintState !== "idle") {
      return;
    }

    setMintState("minting");

    if (!isConnected) {
      setMintState("idle");
      return errorToast("Connect your wallet first!");
    }

    const mintStage = await getMintStage();

    if (mintStage == 0) {
      setMintState("idle");
      return errorToast("Minting is not started yet!");
    }

    const mintPrice = await getMintPrice();
    const contract = await getContract();

    switch (mintStage) {
      case 1: // PRIVATE MINT STARTED
        try {
          if (address == "") {
            return errorToast("There is an issue with your wallet!");
          }

          // console.log(address);

          // console.log(generateProof(address));
          const proof = generateProof(address);

          if (proof.length == 0) {
            return errorToast("Error, please confirm your waitlist position.");
          }

          let tx = await contract.whitelist_mint(proof, {
            value: mintPrice._hex,
            from: address,
          });

          console.log(tx);
          setTxHash(tx.hash);
          setMintState("minted");
        } catch (e) {
          console.log(e);
          const message = e?.reason;
          setMintState("idle");
          if (message) return errorToast(message);
        }
        break;
      case 2:
        // PUBLIC MINT STARTED
        try {
          let tx = await contract.public_mint({
            value: mintPrice._hex,
            from: address,
          });

          console.log(tx);
          setTxHash(tx.hash);
          setMintState("minted");
        } catch (e) {
          console.log(e);
          const message = e?.reason;
          setMintState("idle");
          if (message) return errorToast(message);
        }
        break;
      default:
        setMintState("idle");
        errorToast("Minting is not yet started");
    }
  };

  return (
    <div onClick={onClick} className="mint">
      {mintState !== "idle" ? "MINTING" : "MINT"}
    </div>
  );
}

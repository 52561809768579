import { ethers } from "ethers";

import { isEthereum } from "./core";

export const connect = async () => {
  const ethereum = isEthereum();

  if (!ethereum) {
    throw new Error("Please install MetaMask");
  }

  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  const account = accounts[0];
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  //Set the state of the app here.

  return { account, provider, signer };
};

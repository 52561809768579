import { connected } from "./../state/connect";
import { useRecoilState } from "recoil";

import { connect } from "./../connect";

import { ConnectButton, useConnectModal } from "@web3modal/react";
import { useAccount } from "@web3modal/react";

import { toast } from "react-toastify";

const errorToast = (message) => {
  toast.error(message, { position: "bottom-center" });
};

function IntConnectButton({
  Button,
  text: { unConnected: unConnectedText, connected: connectedText },
}) {
  const { isOpen, open, close } = useConnectModal();
  const { address, isConnected } = useAccount();
  // const [isConnected, setConnected] = useRecoilState(connected);

  // const onTryWalletConnect = async () => {
  //   try {
  //     const { account, provider, signer } = await connect();

  //     console.log({ account, provider, signer });

  //     setConnected({
  //       connected: true,
  //       account,
  //       // provider,
  //       // signer,
  //     });
  //   } catch (error) {
  //     errorToast("Failure! Ensure you have a supported wallet installed.");
  //     console.log(error);
  //   }
  // };

  const TryOpenModal = () => {
    if (!isOpen && !isConnected) {
      open();
    }
  };
  return (
    <div onClick={TryOpenModal}>
      <Button text={!isConnected ? unConnectedText : connectedText} />
    </div>
  );
}

export default IntConnectButton;

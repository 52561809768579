import { ethers } from "ethers";

import web3Config from "../web3.config";

import { Web3ModalEthereum } from "@web3modal/ethereum";

export function isEthereum() {
  if (window.ethereum) {
    return window.ethereum;
  }
}

// export async function getProvider() {
//   if (window.ethereum) {
//     let provider = Web3ModalEthereum.getProvider();
//     let signer = await Web3ModalEthereum.fetchSigner();
//     console.log(provider);
//     console.log(signer);
//     return new ethers.providers.Web3Provider(provider);
//   }
// }

// export async function getSigner() {
//   if (window.ethereum) {
//     const provider = await getProvider();
//     if (provider) {
//       return provider.getSigner();
//     } else {
//       return undefined;
//     }
//   }
// }

export async function getContract() {
  let signer = await Web3ModalEthereum.fetchSigner();

  return new ethers.Contract(
    web3Config.contractAddress,
    web3Config.abi,
    signer
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { RecoilRoot } from "recoil";
// import { WEB3Root } from "./web3/components/root";
import { chains, providers } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";

const config = {
  projectId: "318e1c0f64364844aa59579a25941c12",
  theme: "dark",
  accentColor: "default",

  ethereum: {
    appName: "Library Onchain",
    autoConnect: true,
    chains: [chains.mainnet],
    providers: [
      providers.walletConnectProvider({
        projectId: "318e1c0f64364844aa59579a25941c12",
      }),
    ],
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      {/* <WEB3Root> */}
      <App />
      <Web3Modal config={config} />
      {/* </WEB3Root> */}
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
